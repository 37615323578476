import {forwardRef, useEffect, useState, ReactNode, RefObject} from 'react';
import classNames from 'classnames';
import styles from './styles/CleverSlider.module.scss';

interface CleverSlideContainerProps {
    children: ReactNode[];
    className: string;
    trackClass?: string;
    currentIndex: number;
    setAllowSlideRight?: (allowSlideRight: boolean) => void;
    allowSlideRight?: boolean;
    padding?: number;
    style: { height: number | string};
    slideMap: RefObject<HTMLDivElement | null>[];
    events: Record<string, any>; // Replace 'any' with the appropriate type
    ref: RefObject<HTMLDivElement>;
    getMore?: () => void;
}

const CleverSlideContainer = forwardRef<HTMLDivElement, CleverSlideContainerProps>((props, ref: RefObject<HTMLDivElement>) => {
    const [trackWidth, setTrackWidth] = useState(Infinity);
    const [childWidths, setChildWidths] = useState<number[]>([]);

    useEffect(() => {
        const cWidths: number[] = [];
        let width = 0;
        props.slideMap?.forEach((value) => {
            const rect = value.current?.getBoundingClientRect();
            if (rect) {
                cWidths.push(rect.width);
                width += rect.width + (props.padding || 0);
            }
        });
        setTrackWidth(width);
        setChildWidths(cWidths);
    }, [props.slideMap]);

    useEffect(() => {
        let totalWidthLeft = 0;
        if (childWidths.length === 0) return;
        for (let i =  props.currentIndex; i < childWidths.length; i++) {
            totalWidthLeft += childWidths[i] + (props.padding || 0);
        }
        if (ref?.current?.clientWidth) {
            if (props.setAllowSlideRight) {
                props.setAllowSlideRight(Number(totalWidthLeft) > ref.current.clientWidth)
            }
        }
    },[props.currentIndex, childWidths.length])

    const trackClassName = classNames({
        [styles.track]: true,
        [props.trackClass || '']: props.trackClass,
    });
    const trackContainerClassName = classNames({
        [styles.trackContainer]: true,
        [props.className]: props.className,
    });

    let translate = 0;
    for (let i = 0; i < props.currentIndex; i++) {
        translate -= childWidths[i] + (props.padding || 0);
    }

    const sliderStyle = {transform: `translateX(${translate}px)`, width: `${trackWidth}px`, height: "100%"}

    return (
        <div ref={ref} className={trackContainerClassName} style={props.style} {...props.events}>
            <div
                className={trackClassName}
                style={sliderStyle}
            >
                {props.children}
            </div>
        </div>
    )
});

CleverSlideContainer.displayName = 'CleverSlideContainer';

export default CleverSlideContainer;
